.hotel-list-page {
  padding: 40px 0;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.page-header {
  text-align: left;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}

.page-header h1 {
  display: flex;
  align-items: center;
  color: #1a237e;
  font-weight: 600;
}

.page-header svg {
  color: #7e57c2;
}

.hotel-list-container {
  margin-top: 20px;
} 