.register-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.register-paper {
  padding: 40px;
  width: 100%;
  max-width: 450px;
  border-radius: 12px !important;
}

.register-title {
  text-align: center;
  color: #1a237e;
  margin-bottom: 24px !important;
  font-weight: 600 !important;
}

.register-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Override autofill styles */
.register-form .MuiTextField-root input:-webkit-autofill,
.register-form .MuiTextField-root input:-webkit-autofill:hover,
.register-form .MuiTextField-root input:-webkit-autofill:focus,
.register-form .MuiTextField-root input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.87) !important;
}

/* Style for input fields */
.register-form .MuiTextField-root {
  background-color: white;
}

.register-form .MuiOutlinedInput-root {
  background-color: white;
}

.register-form .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #1a237e;
}

.register-form .MuiInputLabel-root.Mui-focused {
  color: #1a237e;
}

.submit-button {
  margin-top: 16px !important;
  height: 48px;
  background-color: #1a237e !important;
  text-transform: none !important;
  font-size: 16px !important;
}

.submit-button:hover {
  background-color: #0d1657 !important;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .register-paper {
    padding: 24px;
  }
} 