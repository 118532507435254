.blog-post-page {
  margin: 2rem auto;
  max-width: 1200px;
}

.blog-post-container {
  overflow: hidden;
  position: relative;
}

.blog-post-sidebar {
  float: left;
  width: 300px;
  margin: 0 2rem 1rem 0;
  border-radius: 10px;
  box-shadow: none !important;
}

.blog-post-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
}

.blog-post-meta {
  padding: 1rem;
}

.author-info {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.author-avatar {
  background-color: #1976d2;
}

.author-name {
  font-weight: 600;
  color: #333;
}

.info-chip {
  width: 100%;
  justify-content: center;
}

.blog-post-content {
  color: #333;
  line-height: 1.8;
  text-align: left; 
}

@media (min-width: 900px) {
  .blog-post-content {
    margin-top: -25px;
  }
}

.blog-post-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #1a1a1a;
  margin: 0 0 2rem 0;
  padding: 0;
  line-height: 1.2;
}

/* Content Styling */
.blog-post-content strong {
  color: #1a1a1a;
  font-weight: 600;
}

.blog-post-content p {
  margin-bottom: 1.5rem;
}

.blog-post-content ul, 
.blog-post-content ol {
  margin: 1.5rem 0;
  padding-left: 2rem;
}

.blog-post-content li {
  margin-bottom: 0.5rem;
}

.blog-post-content a {
  color: #1976d2;
  text-decoration: none;
}

.blog-post-content a:hover {
  text-decoration: underline;
}

.blog-post-content h1,
.blog-post-content h2,
.blog-post-content h3,
.blog-post-content h4,
.blog-post-content h5,
.blog-post-content h6 {
  margin: 2rem 0 1rem;
  color: #1a1a1a;
}

/* Responsive Design */
@media (max-width: 768px) {
  .blog-post-sidebar {
    float: none;
    width: 100%;
    margin: 0 0 2rem 0;
  }

  .blog-post-content {
    clear: both;
  }

  .blog-post-title {
    font-size: 2rem;
  }
} 