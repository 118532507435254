/* Hero Section */
.hero-section {
  /* background-color: #2980b9; */
  color: white;
  padding: 5rem 0;
  position: relative;
  overflow: hidden;
}

.hero-content {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.hero-title {
  font-weight: 700 !important;
  margin-bottom: 1.5rem !important;
  line-height: 1.2 !important;
}

.hero-subtitle {
  opacity: 0.9;
  margin-bottom: 2rem !important;
  line-height: 1.6 !important;
  max-width: 600px;
  margin-left: auto !important;
  margin-right: auto !important;
}

.hero-cta-container {
  display: flex;
  justify-content: center;
}

.hero-cta {
  padding: 12px 32px !important;
  font-size: 1.1rem !important;
  background-color: #27ae60 !important;
  transition: all 0.3s ease !important;
}

.hero-cta:hover {
  background-color: #219a52 !important;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(39, 174, 96, 0.3) !important;
}

/* Blog Section */
.blog-section {
  padding: 4rem 0;
  background-color: #f8f9fa;
}

/* Blog Cards */
.blog-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  border-radius: 12px;
  overflow: hidden;
  margin: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.blog-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 20px !important;
}

.blog-title {
  min-height: 64px;
  margin-bottom: 1rem !important;
}

.blog-meta {
  margin-bottom: 1rem;
}

.blog-excerpt {
  flex-grow: 1;
  margin-bottom: 1.5rem !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.author-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.post-info {
  display: flex;
  gap: 8px;
}

.info-chip {
  padding: 0 4px !important;
}

.info-chip .MuiChip-label {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.info-chip .MuiChip-icon {
  margin-left: 4px !important;
}

/* Responsive */
@media (max-width: 600px) {
  .hero-title {
    font-size: 2rem !important;
  }
  
  .hero-subtitle {
    font-size: 1.1rem !important;
  }
}

/* About Section */
.about-section {
  padding: 4rem 0;
  background-color: #f0f8ff;
  text-align: center;
}

.mission-section {
  padding: 4rem 0;
  background-color: #e6f7ff;
  text-align: center;
}

.why-choose-us-section {
  padding: 4rem 0;
  background-color: #f0f8ff;
  text-align: center;
}

.about-section h4,
.mission-section h4,
.why-choose-us-section h4 {
  color: #2c3e50;
  margin-bottom: 1rem;
}

.about-section p,
.mission-section p,
.why-choose-us-section p {
  color: #34495e;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

/* Responsive */
@media (max-width: 600px) {
  .about-section,
  .mission-section,
  .why-choose-us-section {
    padding: 2rem 0;
  }
}

/* General Section Styles */
.about-section,
.mission-section,
.why-choose-us-section,
.three-column-section {
  padding: 4rem 0;
  margin-bottom: 2rem;
  text-align: center;
}

.about-section {
  background-color: #f0f8ff;
}

.mission-section {
  background-color: #e6f7ff;
}

.why-choose-us-section {
  background-color: #f0f8ff;
}

.three-column-section {
  background-color: #ffffff;
}

.about-section h4,
.mission-section h4,
.why-choose-us-section h4,
.three-column-section h5 {
  color: #2c3e50;
  margin-bottom: 1rem;
}

.about-section p,
.mission-section p,
.why-choose-us-section p,
.three-column-section p {
  color: #34495e;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

/* Responsive */
@media (max-width: 600px) {
  .about-section,
  .mission-section,
  .why-choose-us-section,
  .three-column-section {
    padding: 2rem 0;
  }
} 