.hotel-hero {
  position: relative;
  width: 100%;
  height: 50vh;
  min-height: 300px;
  max-height: 600px;
  overflow: hidden;
}

.hotel-hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.hotel-hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0,0,0,0.4), rgba(0,0,0,0.6));
  display: flex;
  align-items: center;
  padding: 2rem;
}

.hotel-hero-content {
  max-width: 1200px;
  margin: 0 auto;
  transform: translateY(-20%);
}

.hotel-hero-title {
  color: white;
  margin-bottom: 1.5rem;
  font-size: 2.5rem;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

.hotel-hero-location {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.location-chip,
.hero-chip,
.rating-chip {
  background: rgba(0, 0, 0, 0.6) !important;
  color: white !important;
  padding: 0.25rem 0.75rem;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  backdrop-filter: blur(4px);
}

.rating-chip .star-icon {
  color: #ffd700 !important;
}

.casino-type-chip .casino-icon {
  color: #000 !important;
}

/* Mobile Responsive Adjustments */
@media (max-width: 600px) {
  .hotel-hero {
    height: 40vh;
    min-height: 250px;
  }

  .hotel-hero-overlay {
    padding: 1rem;
  }

  .hotel-hero-content {
    transform: translateY(-10%);
  }

  .hotel-hero-title {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  .hotel-hero-location {
    gap: 0.5rem;
  }

  .location-chip,
  .hero-chip,
  .rating-chip {
    font-size: 0.875rem;
    height: 28px;
  }
}

.hotel-section {
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.hotel-amenities {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.quick-info-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.price-range {
  color: #2e7d32;
  margin-bottom: 0.5rem;
}

.info-grid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.casino-details-table {
  width: 100%;
}

.features-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.games-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.feature-chip {
  margin: 4px 8px 4px 0;
}

.features-grid {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
}

.games-divider {
  margin: 24px 0 16px 0;
}

.games-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 16px;
}

.game-chip {
  margin: 4px;
}

.casino-subsection {
  margin: 32px 0;
  padding-top: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.casino-subsection:first-of-type {
  border-top: none;
}

.detail-label {
  width: 40%;
}

.detail-value {
  text-align: right !important;
}

.details-table td {
  padding: 8px 16px;
}

.tabs-section {
  margin-bottom: 2rem;
}

.detail-tabs {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.tab-content {
  padding: 24px;
}

.reviews-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem;
}

.review-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  transition: transform 0.2s ease;
}

.review-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.review-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid #f0f0f0;
}

.review-user {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}

.user-avatar {
  background-color: #e0e0e0;
  color: #666;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.review-rating {
  display: flex;
  gap: 4px;
  padding: 4px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.03);
}

.review-rating:hover .star-icon {
  animation: starBounce 0.5s ease infinite;
}

.star-icon {
  animation: starPop 0.3s ease-out forwards;
  transform: scale(0);
}

.star-filled {
  color: #ffd700;
}

.star-empty {
  color: #e0e0e0;
}

@keyframes starPop {
  0% {
    transform: scale(0) rotate(-180deg);
  }
  75% {
    transform: scale(1.2) rotate(10deg);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}

@keyframes starBounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
}

.review-content {
  padding: 16px 20px;
}

.review-quote {
  margin: 0;
  padding-left: 1rem;
  border-left: 3px solid #e0e0e0;
  color: #333;
  line-height: 1.6;
  font-style: italic;
  text-align: left;
}

.date-chip {
  background-color: #f5f5f5 !important;
  font-size: 0.75rem !important;
  height: 24px !important;
}

@media (max-width: 600px) {
  .review-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .review-user {
    gap: 8px;
  }

  .date-chip {
    margin-left: 0;
  }
} 