.navbar {
  background-color: #143952 !important;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1) !important;
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100% !important;
  overflow-x: hidden;
}

/* Add container styles */
.navbar .MuiToolbar-root {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Adjust brand logo and mobile menu positioning */
.brand-logo {
  flex-grow: 0;
  margin-right: auto;
}

.mobile-menu-button {
  margin-left: auto;
  margin-right: 8px !important;
  display: inline-flex;
}

/* Add padding for smaller screens */
@media (max-width: 600px) {
  .navbar .MuiToolbar-root {
    padding: 0 16px;
    min-height: 56px !important;
  }

  .brand-logo {
    font-size: 1.2rem !important;
  }

  .brand-logo .logo-icon {
    font-size: 28px !important;
  }

  .mobile-menu-button {
    margin-right: 8px !important;
  }
}

.navbar .brand-logo {
  font-family: "Orbitron", sans-serif;
  font-size: 1.5rem !important;
  letter-spacing: 0.5px;
}

.navbar a {
  text-decoration: none;
  transition: opacity 0.2s ease;
}

.navbar a:hover {
  opacity: 0.9;
}

.navbar button {
  text-transform: none !important;
  border-radius: 8px !important;
  transition: all 0.2s ease-in-out !important;
}

/* Navigation button hover effects */
.navbar button:not(.login-btn):not(.register-btn):hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
  transform: translateY(-1px);
}

/* Login button styles */
.navbar .login-btn {
  border-color: #ecf0f1 !important;
  color: #ecf0f1 !important;
}

.navbar .login-btn:hover {
  background-color: rgba(236, 240, 241, 0.1) !important;
  transform: translateY(-1px);
}

/* Register button styles */
.navbar .register-btn {
  background-color: #27ae60 !important;
  color: white !important;
}

.navbar .register-btn:hover {
  background-color: #219a52 !important;
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(39, 174, 96, 0.3) !important;
}

/* Mobile Menu Styles */
.mobile-menu-button {
  display: none !important;
}

.mobile-menu {
  width: 250px;
  background-color: #143952 !important;
  color: white;
  height: 100%;
}

.mobile-menu-header {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.mobile-menu-header .close-button {
  color: #ecf0f1;
}

.mobile-menu-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.mobile-menu .MuiButton-root {
  justify-content: flex-start;
  padding: 0.8rem 1rem;
  color: #ecf0f1 !important;
}

/* Style the mobile buttons to match desktop */
.mobile-menu .login-btn {
  border-color: #ecf0f1 !important;
  color: #ecf0f1 !important;
  margin: 0.5rem 0;
}

.mobile-menu .register-btn {
  background-color: #27ae60 !important;
  color: white !important;
  margin: 0.5rem 0;
}

.mobile-menu .register-btn:hover {
  background-color: #219a52 !important;
}

/* Add hover effects consistent with desktop */
.mobile-menu .MuiButton-root:not(.login-btn):not(.register-btn):hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.mobile-menu .login-btn:hover {
  background-color: rgba(236, 240, 241, 0.1) !important;
}

.mobile-drawer .MuiPaper-root {
  background-color: #143952 !important;
}

.mobile-drawer .MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

/* Desktop Menu */
.desktop-menu {
  display: flex;
  align-items: center;
}

/* Media Queries */
@media (max-width: 900px) {
  .desktop-menu {
    display: none;
  }
  
  .mobile-menu-button {
    display: inline-flex !important;
  }
} 

/* Logo Styles */
.brand-logo {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.brand-logo a {
  display: flex;
  align-items: center;
  gap: 8px;
  color: inherit;
}

.brand-logo .logo-icon {
  font-size: 36px !important;
  color: #ffffff !important; /* Golden yellow base */
  filter: drop-shadow(0 0 4px #94d3f8); /* Enhanced glow on hover */
  transition: all 0.3s ease;
}

.brand-logo .logo-icon:hover {
  filter: drop-shadow(0 0 4px #94d3f8); /* Enhanced glow on hover */
}