.hotel-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: transform 0.2s;
}

.hotel-card:hover {
  transform: translateY(-5px);
}

.hotel-image {
  object-fit: cover;
}

.hotel-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
}

.hotel-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.hotel-title {
  font-weight: 600;
  flex: 1;
  margin-right: 1rem;
  text-align: left;
}

.rating-box {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #f8f9fa;
  padding: 4px 8px;
  border-radius: 4px;
}

.star-icon {
  color: #ffc107;
}

.hotel-location {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.casino-type {
  display: flex;
  align-items: center;
  gap: 4px;
}

.casino-icon {
  color: #9575cd !important;
  font-size: 20px !important;
}

.hotel-highlights {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin-bottom: 0.4rem;
  justify-content: center;
}

.feature-chip {
  background-color: #f5f5f5;
}

.hotel-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: auto;
}

.price-info {
  display: flex;
  flex-direction: column;
}

.price {
  color: #2e7d32;
  font-weight: 600;
}

.view-details-btn {
  background-color: #1976d2;
  color: white;
  text-transform: none;
}

.view-details-btn:hover {
  background-color: #1565c0;
}

.highlighted-review {
  margin: 1rem 0;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.review-quote {
  font-style: italic;
  color: #555;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.review-author {
  font-size: 0.8rem;
  color: #666;
  font-weight: 600;
  width: 100%;
  text-transform: capitalize;
}

.country-info {
  display: flex;
  align-items: center;
  gap: 6px;
}

.country-flag {
  width: 1.2em !important;
  height: 1.2em !important;
  border-radius: 8px;
} 