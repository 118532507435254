.blog-section {
  padding: 2rem 0;
}

.blog-card {
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.blog-card:hover {
  transform: translateY(-5px);
}

.blog-image {
  object-fit: cover;
}

.blog-content {
  padding: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.blog-title {
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blog-meta {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.post-info {
  display: flex;
  gap: 0.5rem;
}

.info-chip {
  background-color: #e0f7fa;
}

.blog-excerpt {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 1rem !important;
  color: #666;
}

.blog-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding-top: 1rem;
  border-top: 1px solid #eee;
}

.author-info {
  display: flex;
  align-items: center;
}

.author-details {
  margin-left: 0.5rem;
}

.read-more-btn {
  color: #3f51b5;
}

.author-avatar {
  background-color: #6875bd;
}

/* Responsive styles */
@media (max-width: 960px) {
  .blog-section {
    padding: 1rem 0;
  }

  .blog-card {
    margin-bottom: 1rem;
  }
}
