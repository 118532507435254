.footer-page-container {
  padding: 48px 24px;
  min-height: calc(100vh - 64px - 200px); /* Adjust based on your navbar and footer heights */
}

.page-title {
  color: #1a237e;
  margin-bottom: 40px !important;
  font-weight: 600 !important;
  text-align: center;
}

.content-section {
  max-width: 800px;
  margin: 0 auto;
}

.faq-section {
  max-width: 800px;
  margin: 0 auto;
}

.faq-section .MuiAccordion-root {
  margin-bottom: 16px;
  border-radius: 8px !important;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1) !important;
}

.faq-section .MuiAccordionSummary-root {
  background-color: #f5f5f5;
}

.content-section h5 {
  color: #1a237e;
  margin-top: 32px !important;
}

.content-section p {
  margin-bottom: 24px;
  line-height: 1.6;
}

@media (max-width: 600px) {
  .footer-page-container {
    padding: 32px 16px;
  }

  .page-title {
    font-size: 2rem !important;
    margin-bottom: 24px !important;
  }
} 